$( document ).ready(function() {
    $(".js-select").styler();

    $(".js-share-link").on("click", function () {
        let url = location.href;
        $(".js-share-input").val(url);
        $(".js-share-input").select()
        document.execCommand("copy");
    });

    $(".js-search-btn").on("click", function () {
        $(".js-search").toggleClass("is-active");
    });

$(".js-popup-open").on("click", function () {
    var popup = $(this).data("popup");

    // console.log("popup = " , popup);

    $("body").addClass("is-hidden");
    $(".js-popup[data-popup=" + popup + "]").addClass("is-open");
    return false;
});

$(document).on("click", function (e) {
    var popup = $(".js-popup");
    if (popup.is(e.target)) {
        $(popup).removeClass("is-open");
        $("body").removeClass("is-hidden");
        $(".js-form-ok").removeClass("is-active");
    }
});

$(".js-popup-close").on("click", function () {
    $("body").removeClass("is-hidden");
    $(this).parents(".js-popup").removeClass("is-open");
    $(this).parents(".js-popup").removeClass("is-ok");
});

$(".js-form-ok-close").on("click", function () {
    $("body").removeClass("is-hidden");
    $(".js-form-ok").removeClass("is-active");
    $(".js-popup").removeClass("is-open");

    setTimeout(function () {
        $(".js-popup").removeClass("is-ok");
    }, 3000);
});


    $(".js-phone").mask("+7 999 999-99-99");
    
    $(".js-hamburger").on("click" , function(){ 
        $(".js-mobile-menu").toggleClass("is-active");
        $("body").addClass("is-hidden");
    });

    $(".js-menu-control").on("click" , function(){ 
        $(this).parents("li").find(".js-menu-drop").addClass("is-active");
        return false;
    });

    $(".js-menu-back").on("click" , function(){ 
        $(".js-menu-drop").removeClass("is-active");
    });


    $(".js-mobile-menu-close").on("click" , function(){ 
        $(".js-mobile-menu").toggleClass("is-active");
        $("body").removeClass("is-hidden");
    });



    $(".js-count").spinner({
        min: 5,
        max: 2500,
        step: 25,
        start: 1000,
        spin: function() {
            $(this).val($(this).val()+' Га');
        },
        stop: function() {
            $(this).val($(this).val()+' Га');
        }
    });

    $(".js-tab-item").on("click", function () {
        var tabNumber = $(this).data("tab"),
            parents = $(this).parents(".js-tab");

        parents.find(".js-tab-item").removeClass("is-active");
        $(this).addClass("is-active");

        parents.find(".js-tab-block").removeClass("is-active");
        parents.find(".js-tab-block[data-tab=" + tabNumber + "]").addClass("is-active");
    });
    

    if ($(window).width() > 960) {
        $(".js-aria-aside").stick_in_parent({
            offset_top: 180
        });
    }

    $(".js-mservice").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        autoplay: true,
        dots: false,
        arrows: true,
        fade: true,
        infinite: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>"
    });

    $(".js-mservice").on('afterChange', function(event, slick, currentSlide, nextSlide){
        console.log(currentSlide);

        $(".js-mservice-item").removeClass("is-active");
        $(".js-mservice-item[data-index="+currentSlide+"]").addClass("is-active");
      });

    $(".js-ibanner").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        arrows: true,
        fade: true,
        infinite: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>"
    });

    $(".js-stock-main").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        dots: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>",
        responsive: [
            {
                breakpoint: 1310,
  
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 960,
  
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    });

    $(".js-event").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        dots: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>",
        responsive: [
            {
                breakpoint: 1310,
  
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 960,
  
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    });

    $(".js-field-card").slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        dots: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>",
        responsive: [
            {
                breakpoint: 1310,
  
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 641,
  
                settings: {
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    });

    $(".js-product-card").slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        dots: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>",
        responsive: [
            {
                breakpoint: 1310,
  
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 641,
  
                settings: {
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    });

    $(".js-stock-detail").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        dots: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>",
        responsive: [
            {
                breakpoint: 1310,
  
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 960,
  
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 641,
  
                settings: {
                    variableWidth: true,
                    arrows: false,
                    slidesToScroll: 1
                }
            }
        ]
    });
    
    $(".js-stock-page").slick({
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true,
        dots: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>",
        responsive: [
            {
                breakpoint: 768,
  
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    

    $(".js-field-full").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        dots: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>",
        responsive: [
            {
                breakpoint: 1310,
  
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 960,
  
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 641,
  
                settings: {
                    variableWidth: true,
                    arrows: false,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $(".js-product-full").slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        dots: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>",
        responsive: [
            {
                breakpoint: 1310,
  
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 960,
  
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 641,
  
                settings: {
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    });

    $(".js-stock-full").slick({
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true,
        dots: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>",
        responsive: [
            {
                breakpoint: 1310,
  
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 960,
  
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 640,
  
                settings: {
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    });

    $(".js-stock-card").slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        dots: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>",
        responsive: [
            {
                breakpoint: 1310,
  
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 641,
  
                settings: {
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    });


    $(".js-brand").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        dots: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg  width='64' height='56' viewBox='0 0 64 56' class='svg-icon'><use xlink:href='#svg-arrow-back'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg  width='64' height='56' viewBox='0 0 64 56' class='svg-icon'><use xlink:href='#svg-arrow-back'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>",
        responsive: [
            {
                breakpoint: 1310,
  
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },{
                breakpoint: 960,
  
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },{
                breakpoint: 640,
  
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $(".js-slider-img").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        arrows: true,
        fade: true,
        infinite: false,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-left'></use></svg>\
        </div></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <div class='slick-arrow__block'>\
        <div class='slick-arrow__back'><svg width='36' height='32' viewBox='0 0 36 32' class='svg-icon'><use xlink:href='#svg-arrow-back-small'></use></svg>\</div>\
        <svg  width='10' height='15' viewBox='0 0 10 15' class='svg-icon'><use xlink:href='#svg-arrow-right'></use></svg>\
        </div></button>"
    });
































/*** END POPUP ***/

    $(window).scroll(function() {     
        if ($(window).scrollTop() > 300) {
           $(".js-up").addClass('is-active');
         } else {
            $(".js-up").removeClass('is-active');
         }
       });

       $(".js-up").on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, '300');
      });


    

   



    /*** FORM ***/



    $(".js-stock").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 992,
  
                settings: {

                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 560,
  
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });






    if ($('#map').length) {
        var init = function init() {
            var myMap = new ymaps.Map("map", {
                center: [47.266527, 39.719064],
                zoom: 12
            });

            var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
                iconLayout: 'default#image',
                iconImageHref: './images/common/marker.svg',
                iconImageSize: [50, 60],
                iconImageOffset: [-25, -60]
            });

            var myPlacemark1 = new ymaps.Placemark([47.295986, 39.711959], {}, {
                iconLayout: 'default#image',
                iconImageHref: './images/common/marker.svg',
                iconImageSize: [50, 60],
                iconImageOffset: [-25, -60]
            });

            var myPlacemark2 = new ymaps.Placemark([47.229929, 39.621310], {}, {
                iconLayout: 'default#image',
                iconImageHref: './images/common/marker.svg',
                iconImageSize: [50, 60],
                iconImageOffset: [-25, -60]
            });



            myMap.controls.remove('zoomControl');
            myMap.behaviors.disable('scrollZoom');
            myMap.behaviors.disable('drag');
            myMap.geoObjects.add(myPlacemark);
            myMap.geoObjects.add(myPlacemark1);
            myMap.geoObjects.add(myPlacemark2);
        };

        ymaps.ready(init);
    }

});